import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer() {
    return (
        <Container id="footer">
            <Row>
                <Col xs lg={10}></Col>
                <Col xs lg={2}>&copy;2025 jake-williamson.com</Col>
            </Row>
        </Container>
    );
}

export default Footer;
