import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Projects from "./Projects";

function Body() {
    return (
        <Container id="body-container">
            <Row className="greeting">
                <Col className="body-left" lg={2}></Col>
                <Col className="body-midde">Hello!</Col>
                <Col className="body-right"></Col>
            </Row>
            <Row>
                <br/>
            </Row>
            <Row>
                <Col className="body-left" lg={2}></Col>
                <Col className="body-middle gradient-border-left" lg={6}>
                    <Container>
                        <div>My name is Brian "Jake" Williamson. I worked as a full-stack engineer for over a decade, but am
                            primarily focused on the back-end these days. I've worked on many things from e-commerce
                            applications, to finance, and crypto.
                        </div>
                        <br/>K
                        <div>I've worked for Fortune 400 companies, but my true love is with startups.</div>
                        <br/>
                        <div>I'm passionate about building reliable, scalable, and secure cloud-native applications that
                            make a positive impact in the world.
                        </div>
                    </Container>
                </Col>
                <Col lg={1}/>
                <Col className="body-right" lg={2}>
                    <Projects/>
                </Col>
            </Row>
            <Row>
                <Col className="body-left" lg={2}></Col>
                <Col className="body-middle" lg={6}>
                    <br/>
                    <Container>
                        <a href="https://www.credly.com/badges/0788d388-a361-4558-a47b-25bee16baa5d/public_url"
                           target="_blank">
                            <img
                                className="certificate"
                                src={process.env.PUBLIC_URL + "/aws-certified-solutions-architect-associate.png"}
                                alt="AWS Certified Solutions Architect - Associate"
                            />
                        </a>
                        <a href="https://www.credly.com/badges/c55b16fc-386d-4138-b52e-ca0d44de71b6/public_url"
                           target="_blank">
                            <img
                                className="certificate"
                                src={process.env.PUBLIC_URL + "/aws-certified-developer-associate.png"}
                                alt="AWS Certified Developer - Associate"
                            />
                        </a>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default Body;
